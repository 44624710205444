<script>
import { nextTick } from "vue";
import lottie from "lottie-web";
import arrow from "@/assets/json/arrow-white.json";
import PopupShare from '@/components/popupShare/index.vue'
import liff from "@line/liff";

export default {
	name: "Share",
	computed: {
		userNTInfo(){
			return this.$store.state.userNTInfo;
		},
	},
	data(){
		return{
			aniArrow: "",
			activeTab: "rule",
			isActivePopupShare: false
		}
	},
	components: {
		PopupShare
	},
	mounted(){
		nextTick(() => {
			let vm = this;
			vm.aniArrow = lottie.loadAnimation({
				container: document.getElementById("aniArrow"),
				animationData: arrow,
				renderer: "svg",
				loop: true,
				autoplay: true, 
				name: "aniArrow", 
			});
		});
	},
	beforeUnmount(){
		let vm = this;
		vm.aniArrow.destroy()
	},
	methods:{
		share(){
			// console.log("share")
			let vm=this

			liff
				.shareTargetPicker(
					[
						{
							type: "text",
							text: `中元傳雀巢，逗陣集好運！\n買雀巢就能抽蘋果系列商品及秒殺演唱會門票\n(點擊後視為與好友綁定) \n https://liff.line.me/${process.env.VUE_APP_liffID}?code=${vm.userNTInfo.data['invite-code']}`
						},
					],
					{
						isMultiple: true,
					}
				)
				.then(function (res) {
					if (res) {
						vm.isActivePopupShare=true
					} 
				})
				.catch(function (error) {
					console.log("something wrong happen");
				});
		},
		changeTab(target){
			// console.log("changeTab",target)
			let vm = this;
			vm.activeTab = target;
		},
	}
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="sass" scoped src="./style.sass"></style>